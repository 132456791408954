import React, { useEffect } from "react";
import { Link } from 'react-router-dom'
import './Home.css'

function Home(){

    useEffect(() => {
        document.title = "Hradní Svíce";
    }, []);

    return(
        <div className="container">
            <main className="main-element-home">
            <h2>Úvodem:</h2>
                <div className="content-wrap-home">
                    <article className="home-text-container">
                        <p>
                            Nekorektní komedie Hradní Svíce, která se odehrává v fiktivním, stejnojmenném městě, které je vším, jen ne městem snů. 
                            Vypráví příběh tří spolužáků a dobrých kamarádů - Vika, Tuby a Hynka a dvou záporných postav, Štefana a Darina. 
                            Ve filmu se objevují i další postavy jako agresivní alkoholik Mikva, neúspěšný zpěvák Štanclík, vařič pervitinu Urban, šikanovaný chlapec Lojza či trojice dívek - Kristýna, Lucie a Sofie (všechny tři jsou nešťasně zamilované do Tuby).
                            Alkáči, máničky, teplouši, dealeři, feťáci, socky, básníci, kurvy a sprejeři - to, a mnoho dalších podobných bytostí je denním pikem tohoto města.
                            Děj filmu pojednává o jednom průměrném týdnu všech těchto postav.
                        </p>
                    </article>
                    <div className="home-text-container">
                        <div class="video-container">
                        <iframe
                               src="https://www.youtube.com/embed/QSlKm4uL1F0?si=bR6iJZp3G5Si2ikj"  title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen
                                className="trailer-preview-yt"
                            ></iframe>
                        </div>
                    </div>
                </div>    
                <article className="second-content-wrap">
                    <h2>Co dál?</h2>
                    <p>
                        No, napsané to vypadá hezky, realita je bohužel trochu jiná. Natočit celovečerní film, i když v takovýchto podmínkách, není úplně levnou záležitostí (ty braníky a toluen taky něco stojí). Proto se obracíme na Vás - pokud jste také fanoušky měkkých drog, střelných zbraní a žen svázaných lanem, navštivte prosím kartu <Link to="/prispevky">Příspěvky</Link>. Na této kartě může finančně podpořit natočení tohoto trháku, a za poslané peníze si můžete vybrat nějakou ze symbolických odměn.
                    </p>
                </article>
                <article className="third-content-wrap">
                    
                    <div className="donations-column">
                        <h3>Poděkování patří:</h3>
                        <p>
                            Web - Matěj Vít
                        </p>
                        <p>
                            Obecní hostinec obec Jeníkovice
                        </p>
                        <p>
                            Spolek CIHELNA
                        </p>
                        <p>
                            RFV rave a Alex Hvězda
                        </p>
                        <p>
                            Autodoprava David Drin
                        </p>
                        <p>
                            Obec České Meziříčí a obec Jeníkovice
                        </p>
                        <p>
                            VFX support - Matyáš Chytil
                        </p>
                        <p>
                            Prdecky Dobre Hrusky
                        </p>
                        <p>
                            a mnohým dalším, děkujeme!
                        </p>
                    </div>
                    <div className="donations-column">
                        <h3>Děkujeme našim dárcům:</h3>
                        <p>
                            Eliška Králová
                        </p>
                        <p>
                            Ondřej Maga
                        </p>
                        <p>
                            Petr Svoboda
                        </p>
                        <p>
                            Čeněk Rada
                        </p>
                        <p>
                            Pavel Dašek
                        </p>
                    </div>
                
                </article>
            </main>
        </div>
    )
}

export default Home;