import React, { useState, useEffect } from "react";
import whiteLogo from '../images/white-logo.png'
import './Navbar.css'
import { NavLink, Link } from "react-router-dom";
import { Turn as Hamburger } from 'hamburger-react'

function Navbar() {
    

    const [click, setClick] = useState(false)
    const [menuBtn, setMenuBtn] = useState(false)
    const [overlay, setOverlay] = useState(false)
    const [windowSize, setWindowSize] = useState(window.innerWidth)

    const toggleMenu = () => {
        setClick((prevClick) => !prevClick);
        setOverlay(!overlay);
    };

    const checkContainerWidth = () => {
        const newWindowSize = window.innerWidth
        setWindowSize(newWindowSize);

        if (newWindowSize <= 765) {
            setMenuBtn(true)
        } else if (newWindowSize >= 765) {
            setClick(false);
            setMenuBtn(false)
            setOverlay(false);
        }
    };

    useEffect(() => {
        checkContainerWidth();
        window.addEventListener('resize', checkContainerWidth)

        return () => {
            window.removeEventListener('resize', checkContainerWidth)
        };
    }, []);

    return (
        <>
            <nav>
                <div className="navbar-content-wrap">
                    <img src={whiteLogo} className="logo"></img>
                    {menuBtn ? (
                        <div id="overlayMenu" className={overlay ? "overlayOpen" : ""}>
                            <ul className="overlayList">
                                <li className="overlayLi"><NavLink to="/">Domů</NavLink></li>
                                <li className="overlayLi"><NavLink to="/postavy">Postavy</NavLink></li>
                                <li className="overlayLi"><NavLink to="/prispevky">Příspěvky</NavLink></li>
                            </ul>
                        </div>
                    ) : (
                        <ul>
                            <li>
                                <NavLink to="/">Domů</NavLink>
                            </li>
                            <li>
                                <NavLink to="/postavy">Postavy</NavLink>
                            </li>
                            <li>
                                <NavLink to="/prispevky">Příspěvky</NavLink>
                            </li>
                        </ul>
                    )}
                    
                </div>
                {menuBtn &&
                <div className="hamburger-menu-wrap">
                    <Hamburger direction="right" color="white" duration={0.25} onToggle={toggleMenu}/>
                </div>
            }
            </nav>
            
        </>
    )
}

export default Navbar;
