import React, { useEffect } from "react";
import './Contact.css'
import qr from '../images/qr-kod.jpg'
import { BsInstagram } from 'react-icons/bs';
import { AiOutlinePhone } from 'react-icons/ai';
import { FiMail } from 'react-icons/fi';

function Contact(){

    useEffect(() => {
        document.title = "Hradní Svíce | Příspěvky";
    }, []);

    return(
        <main className="main-element-contact">
            <section>
                <h1 className="contact-title">Příspěvky</h1>
                <article>
                    <p>
                        Pokud chcete finančně přispět a tím podpořit vznik celého filmu, budeme pochopitelně jedině rádi.
                        
                        (pozn. red - a konečně si třeba budeme moct dovolit někoho, kdo umí psát).
                    </p>
                    <h3 className="article-titles">Zde jsou způsoby, jak je možné přispět:</h3>
                    <p className="">
                        <span className="bold">1) Přes web</span> <a href="https://www.startovac.cz/projekty/hradni-svice-1" target="_blank">www.startovac.cz</a> <span className="bold">(prozatím nespuštěno)</span>
                    </p>
                    <div className="contact-t-wrap">
                        <p className="margin-adjust-text"><span className="bold">2)</span></p> 
                        <p className="margin-adjust-text">
                            <span className="bold">Přes přiložený QR kod</span>, či na toto číslo účtu: <span className="bold">6834892399/0800 </span> 
                            (za zaslané peníze je možné vybrat nějakou z odměn, ty jsou vypsány níže, k platbě tedy prosím do poznámky napište, Vaše telefonní číslo či email)
                        </p>
                    </div>
                </article>


                <div className="qr-wrap">
                    <img src={qr} className="qr" alt="qr-kod"></img>
                </div>
            </section>
            <section>
                <article>
                    <h3 className="article-titles">Vyberte si odměnu:</h3>
                    <p>
                        <span className="bold">Přispějte 200 Kč a více</span>
                        <br></br>
                        Poděkování v titulcích a na webu
                    </p>
                    <p>
                        <span className="bold">Přispějte 500 Kč a více</span>
                        <br></br>
                        Hrnek Hradní Svíce
                    </p>
                    <p>
                        <span className="bold">Přispějte 800 Kč a více</span>  
                        <br></br>
                        Tričko Hradní Svíce
                    </p>
                    <p>
                        <span className="bold">Přispějte 1 000 Kč a více</span>
                        <br></br>
                        Dobroty od chemika Arnošta Urbana
                    </p>
                    <p>
                        <span className="bold">Přispějte 2 000 Kč a více</span> <br/>
                        <span className="bold">1)</span> Romantická večeře s Jarmilem Kočím<br/>
                        <span className="bold">2)</span> Lekce kalení s Kamilem Mikvou<br/>
                        <span className="bold">3)</span> Hodina šikanování Lojzy Prášila<br/>
                        (vyberte jednu z možností)
                    </p>
                    <p>
                        <span className="bold">Přispějte 5 000 Kč a více</span>
                        <br/>
                        Procházka s herci po městě Hradní Svícíce
                    </p>
                    <p>
                        <span className="bold">Přispějte 10 000 Kč a více</span> <br/>
                        Kuřba od režiséra
                    </p>
                </article>
            </section>
            <footer>
               <h3 className="article-titles">Kontakt</h3>
                <div className="icon-wrap">
                    <div>
                        <a className="icon-a" href="tel:+420739119130" target="_blank">
                            <AiOutlinePhone className="icons phone-icon"></AiOutlinePhone>
                        </a>
                        <p>739 119 130</p>
                    </div>
                    <div>
                        <a className="icon-a" href="mailto:hradni.svice@email.cz" target="_blank">
                            <FiMail className="icons mail-icon"></FiMail>
                        </a>
                        <p>hradni.svice@email.cz</p>
                    </div>
                    <div>
                        <a className="icon-a" href="https://www.instagram.com/hradni_svice/" target="_blank">
                            <BsInstagram className="icons instagram-icon"></BsInstagram>
                        </a>
                        <p>@hradni_svice</p>
                    </div>
                </div>
                <p>
                    V případě jakýchkoliv dotazů nás neváhejte kontaktovat.
                </p>

                <p>
                    Aleš Labuť (režisér)
                    <br />
                    Matyáš Voborník 
                </p>
            </footer>
        </main>
    )
}

export default Contact;