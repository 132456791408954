import React from "react";
import Home from "../components/Home";
import Navbar from "../components/Navbar";

function HomePage(){
    return(
        <>
            <Navbar />
            <Home />
        </>
    )
}

export default HomePage