import img1 from '../images/1.jpg'
import img2 from '../images/2.jpg'
import img3 from '../images/3.jpg'
import img4 from '../images/4.jpg'
import img5 from '../images/5.png'
import img6 from '../images/6.jpg'

import pl2 from '../images/pl2.png'

import img11 from '../images/11.jpg'

const charactersData = [
    {
        id: 1,
        group: true,
        image: img11,
        alt: 'ukazka-skupiny-herci',
        name: "Hlavní trojice",
        description: "Hlavní trojice, jedni z největších bossů z celého města, studenti devátého ročníku tamní základní školy a především velcí milovnici dvoulitrových Braníků a všech možných těkavých aromatických uhlovodíkových látek.",
        nameOne: 'Hynek',
        nameTwo: 'Tuba',
        nameThree: 'Viko',
        descriptionOne: 'Hynek je silným nihilistou, mívá často velmi filozofické myšlenky a názory, není tedy žádným tajemstvím, že je nejchytřejším členem party, avšak, přes to všechno je Hynek bezdomovcem. Bydlí v popelnici a posledních pět let ho nikdo neviděl pozřít nic jiného, než alkohol a plesnivé tukového rohlíky. Také rád čichá toluen a aceton.',
        descriptionTwo: 'Tuba, trochu si sedí na vedení a nevždycky mu všechno dojde na první dobrou. Jako jediný z trojice vlastní auto, o které ve volných chvílích pečuje. Mimo jiné je také velice silným epileptikem. O Tubu mají zájem všechny ženy z širokého okolí, on však zájem neopětuje a raději se stará o svoje auto.',
        descriptionThree: 'Je to nepraktikující abstinent, je ministrantem v místním kostele, což ho také možná lehce poznamenalo - Vika vzrušují mladí, staří, mrtví - nic mu prostě není svaté. A když má krizi, omrdá i přeloženou pizzu. Z celé party má ale největší drip. A ve volném čase se věnuje graffity a kapesním krádežím.',
    },
    {
        id: 2,
        group: false,
        image: img2,
        alt: 'ukazka-herci',
        name: "Štefan a Darin",
        description: 'Dvojice záporných a silně debilních postav, oba dva v životě nic nedokázali, avšak cítí se jako velcí páni, problém je v tom, že za "velké pány" se považují jen oni sami, zbytku města jsou jen pro smích. Štefan se navíc věnuje rapu, opět nutno říct - nijak dobře. A Darin je něco jako takový Štefanův přisluhovač.',
        nameOne: '',
        nameTwo: '',
        nameThree: '',
        descriptionOne: 'Lorem ipsum dolar',
        descriptionTwo: 'Lorem ipsum dolar',
        descriptionThree: 'Lorem ipsum dolar',
    },
    {
        id: 3,
        group: false,
        image: img5,
        alt: 'ukazka-herci',
        name: "Jarmil Kočí",
        description: "Spolužák Vika, Tuby a Hynka, a v podstatě jejich dobrý kamarád. Je to promiskuitní homosexuál a je známý svým občasným obtěžováním mužských obyvatel města. Snaží se zapadnout mezi partu svých spolužaček - Kristýnu, Lucii a Sofii. Velmi rád jezdí na svém elektrickém skútru. Další jeho záliby jsou sběr pampelišek, filumenistika a nepovolené vstupování do řití svých známých.",
        nameOne: '',
        nameTwo: '',
        nameThree: '',
        descriptionOne: 'Lorem ipsum dolar',
        descriptionTwo: 'Lorem ipsum dolar',
        descriptionThree: 'Lorem ipsum dolar',
    },
    {
        id: 4,
        group: false,
        image: img4,
        alt: 'ukazka-herci',
        name: "Arnošt Urban",
        description: "Temná a vcelku nenápadná bytost, je však známý svoji zálibou ve výrobě pervitinu a jiných drog. Jeho dobré skutky jsou pouhým prostředkem pro naplnění vlastních sobeckých cílů. Nejčastěji se vyskytuje v kdysi funkční spalovně Židů. Nyní je tento prostor zcela opuštěn, tedy až na skromný příbytek pana Urbana a jeho laboratoř, ve které si občas zavaří.",
        nameOne: '',
        nameTwo: '',
        nameThree: '',
        descriptionOne: 'Lorem ipsum dolar',
        descriptionTwo: 'Lorem ipsum dolar',
        descriptionThree: 'Lorem ipsum dolar',
    },
    {
        id: 5,
        group: false,
        image: img3,
        alt: 'ukazka-herci',
        name: "Lojza Prášil",
        description: "Sirotek, všemi šikanovaný chlapec a osamělá duše nesoucí tíhu osudu na svých křehkých ramenech. Jeho život byl vždy doprovázen tragédiemi, mimo jiné i hrozivým požárem kadibudky, který vzal Lojzovi rodiče a všech jeho dvanáct sourozenců. V této nelehké situaci a pod tíhou Štefanova a Darinova nátlaku se nedobrovolně stává informátorem. To mu vynese pověst odporného práskače.",
        nameOne: '',
        nameTwo: '',
        nameThree: '',
        descriptionOne: 'Lorem ipsum dolar',
        descriptionTwo: 'Lorem ipsum dolar',
        descriptionThree: 'Lorem ipsum dolar',
    },
    {
        id: 6,
        group: false,
        image: img6,
        alt: 'ukazka-herci',
        name: "Sofie, Kristýna a Lucie",
        description: "Spolužačky hlavní trojice. Všechny tři jsou zamilované do Tuby, svoje city mu dávají plně najevo. On však nemá zájem. Všechny tři také aktivně provozují tzv. hobbyhorsing, což je něco jako jízda na koňské hlavě připevněné k dřevěné tyči (pozn. red - to je tak blbý, že to tam ani nemůžeme napsat) a rády takto jezdí na vyjížďky po celém městě.",
        nameOne: '',
        nameTwo: '',
        nameThree: '',
        descriptionOne: '',
        descriptionTwo: '',
        descriptionThree: '',
    },
]

export default charactersData;