import React, { useEffect } from "react";
import charactersData from "../data/characters-data";
import './Characters.css'

function Characters(){

    useEffect(() => {
        document.title = "Hradní Svíce | Postavy";
    }, []);

    return(
        <div className="container">
            <main className="main-element-characters">
                <h1 className="title-characters">Postavy</h1>
                <section className="character-columns-wrap">
                    {charactersData.map((item) => (
                        <div key={item.id} className="character-column">
                            <img src={item.image} className="chracters-images"></img>
                            <div className="character-text-wrap">
                                <h2 className="character-name">{item.name}</h2>
                                <p className="character-description">{item.description}</p>
                                {item.group &&
                                    <>
                                        <h3 className="character-group-name">{item.nameOne}</h3>
                                        <p className="character-group-description">{item.descriptionOne}</p>
                                        <h3 className="character-group-name">{item.nameTwo}</h3>
                                        <p className="character-group-description">{item.descriptionTwo}</p>
                                        <h3 className="character-group-name">{item.nameThree}</h3>
                                        <p className="character-group-description">{item.descriptionThree}</p>
                                    </>
                                }
                            </div>
                        </div>
                    ))}
                </section>
                <p className="end-text">
                    V celém filmu se objevuje řada dalších existencí, o nich, a mnohém dalším Vás budeme postupně informovat.
                </p>
            </main>
        </div>
    )
}

export default Characters;