import React from "react";
import Contact from "../components/Contact";
import Navbar from "../components/Navbar";

function ContactPage(){
    return(
        <>
            <Navbar />
            <Contact />
        </>
    )
}

export default ContactPage