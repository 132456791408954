import React from "react";
import Characters from "../components/Characters";
import Navbar from "../components/Navbar";

function CharactersPage(){
    return(
        <>
            <Navbar />
            <Characters />
        </>
    )
}

export default CharactersPage