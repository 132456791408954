import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import CharactersPage from './pages/CharactersPage';
import ContactPage from './pages/ContactPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/postavy" element={<CharactersPage />} />
        <Route path="/prispevky" element={<ContactPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
